







import Vue from "vue";
import DashboardComponent from "@/components/DashboardComponent.vue";
import LspDashboardComponent from "@/components/LspDashboardComponent.vue";

export default Vue.extend({
  name: "Dashboard",

  components: {
    DashboardComponent,
    LspDashboardComponent,
  },

  data: () => ({
    app_name: process.env.VUE_APP_TITLE,
  }),
});
