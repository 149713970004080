var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mb-4",attrs:{"cols":"5"}},[_c('h1',{staticClass:"display-2 font-weight-bold mb-3"},[_vm._v("Dashboard")]),_c('p',{staticClass:"subheading font-weight-regular"},[_vm._v(" Op deze pagina ziet u visualisaties en analyses die inzicht geven in de werking en het performen van de applicatie. De pagina toont algemene statistieken zoals het aantal bekende regels en het aantal uitgevoerde regelchecks. Daarnaast kunt u ook zien wat de verhouding positieve en negatieve bevindingen over de tijd is. ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[(_vm.expertStatistics)?_c('v-card',[_c('v-card-title',[_vm._v("Statistieken")]),_c('v-card-text',[_c('p',[_c('b',[_vm._v(_vm._s(_vm.expertStatistics.rules))]),_vm._v(" regels bekend. ")]),_c('p',[_c('b',[_vm._v(_vm._s(_vm.expertStatistics.findings))]),_vm._v(" regelchecks uitgevoerd. ")]),_c('p',[_c('b',[_vm._v(_vm._s(_vm.expertStatistics.batches))]),_vm._v(" leveringen bekend. ")])])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"9"}},[_c('source-statistics-component')],1)],1),_c('v-row',[_c('v-col',[_c('h2',{staticClass:"mb-3"},[_vm._v("Trends per bron per kwaliteitsregel")])])],1),_vm._l((_vm.findingsBatchAreaData),function(value,source){return _c('v-container',{key:source},[_c('v-row',[_c('v-col',[_c('h3',{staticClass:"mb-3"},[_vm._v(_vm._s(source))])])],1),_c('v-row',_vm._l((_vm.findingsBatchAreaData[source][0][1]),function(rule,index){return _c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.findingsBatchAreaData[source].some(
            function (x) { return x[1][index].pass != 0 || x[1][index].fail != 0; }
          )
        ),expression:"\n          findingsBatchAreaData[source].some(\n            (x) => x[1][index].pass != 0 || x[1][index].fail != 0\n          )\n        "}],key:rule[0],attrs:{"cols":"4"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(rule.name)+" ")]),_c('findings-area-chart',{staticStyle:{"height":"250px"},attrs:{"data":_vm.findingsBatchAreaData[source].map(function (x) {
                return {
                  date: x[0].slice(0, 16),
                  true: x[1][index].pass,
                  false: x[1][index].fail,
                };
              })}})],1)],1)}),1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }