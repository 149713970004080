















































































import Vue from "vue";
import axios from "axios";
import FindingsAreaChart from "@/components/charts/FindingsAreaChart.vue";
import { Rule } from "@/types/rule";
import { CountObject } from "@/types/chartTypes";
import { Importance } from "@/types/importance";
import SourceStatisticsComponent from "./SourceStatisticsComponent.vue";

export default Vue.extend({
  name: "LspDashboardComponent",
  components: {
    FindingsAreaChart,
    SourceStatisticsComponent,
  },
  data: () => ({
    rules: [],
    findingsAreaData: [],
    findingsBatchAreaData: {},
    expertStatistics: undefined,
  }),
  computed: {
    rulePieChartData: function (): CountObject[] {
      const presentTypes: string[] = [
        ...new Set(this.$data.rules.map((rule: Rule) => rule.object_type)),
      ] as string[];
      const countObjects = presentTypes.map((type: string) => ({
        key: type,
        count: this.$data.rules.filter((rule: Rule) => rule.object_type == type)
          .length,
      }));
      return countObjects;
    },
    ruleImportancePieChartData: function (): CountObject[] {
      const presentImportances: number[] = [
        ...new Set(this.$data.rules.map((rule: Rule) => rule.importance)),
      ] as number[];
      const countObjects = presentImportances.map((importance: number) => ({
        key: Importance[importance],
        count: this.$data.rules.filter(
          (rule: Rule) => rule.importance == importance
        ).length,
      }));
      return countObjects;
    },
  },
  methods: {
    getRules() {
      axios
        .get(`${this.$store.state.APIurl}/rule`)
        .then((response) => {
          this.$data.rules = response.data;
        })
        .catch((error) => {
          this.$data.rules = [];
        });
    },
    getFindingsAreaData() {
      axios
        .get(`${this.$store.state.APIurl}/finding/area-chart`)
        .then((response) => {
          this.$data.findingsAreaData = response.data;
        })
        .catch((error) => {
          this.$data.findingsAreaData = [];
        });
    },
    getFindingsBatchAreaData() {
      axios
        .get(`${this.$store.state.APIurl}/finding/area-batch-chart`)
        .then((response) => {
          this.$data.findingsBatchAreaData = response.data;
        })
        .catch((error) => {
          this.$data.findingsBatchAreaData = [];
        });
    },
    getExpertStatistics() {
      axios
        .get(`${this.$store.state.APIurl}/statistics`)
        .then((response) => {
          this.$data.expertStatistics = response.data;
        })
        .catch((error) => {
          this.$data.expertStatistics = undefined;
        });
    },
  },
  mounted() {
    this.getRules();
    this.getFindingsAreaData();
    this.getFindingsBatchAreaData();
    this.getExpertStatistics();
  },
});
