









































































































































import Vue from "vue";
import axios from "axios";
import FindingsAreaChart from "@/components/charts/FindingsAreaChart.vue";
import BarChart from "@/components/charts/BarChart.vue";
import { Rule } from "@/types/rule";
import { CountObject } from "@/types/chartTypes";
import { Importance } from "@/types/importance";

export default Vue.extend({
  name: "DashboardComponent",
  components: {
    FindingsAreaChart,
    BarChart,
  },
  data: () => ({
    rules: [],
    findingsAreaData: [],
    expertStatistics: undefined,
    dates: [],
    dialogNoFindings: false,
    noFindingsFound: '',
    isLoading: false,
    menu: false,
    maxDate: null,
    dateRangeText: '',
    errorMessage: '',
  }),
  computed: {
    ruleBarChartData: function (): CountObject[] {
      const presentTypes: string[] = [
        ...new Set(this.$data.rules.map((rule: Rule) => rule.object_type)),
      ] as string[];
      const countObjects = presentTypes.map((type: string) => ({
        key: type,
        count: this.$data.rules.filter((rule: Rule) => rule.object_type == type)
          .length,
      }));
      return countObjects;
    },
    ruleImportanceBarChartData: function (): CountObject[] {
      const presentImportances: number[] = [
        ...new Set(this.$data.rules.map((rule: Rule) => rule.importance)),
      ] as number[];
      const countObjects = presentImportances
        .sort((a, b) => (a > b ? 1 : -1))
        .map((importance: number) => ({
          key: Importance[importance],
          count: this.$data.rules.filter(
            (rule: Rule) => rule.importance == importance
          ).length,
        }));
      return countObjects;
    },
  },
  methods: {
    downloadDump() {
      this.isLoading = true;
      const [start_date, end_date] = this.$data.dateRangeText.split(' ~ ').sort();
      axios({
        url: `${this.$store.state.APIurl}/finding/download`,
        method: "GET",
        responseType: "blob",
        headers: { Authorization: `Bearer ${this.$store.state.user.token}` },
        params: {
          start_date: start_date,
          end_date: end_date,
        },
      })
        .then((response) => {
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fURL = document.createElement("a");
          this.$data.isLoading = false;

          fURL.href = fileURL;
          const currentDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');
          fURL.setAttribute("download", `dump_bevindingen_${currentDate}.csv`);
          document.body.appendChild(fURL);
          fURL.click();
        })
        .catch((error) => {
          this.$data.dialogNoFindings = true;
          this.$data.noFindingsFound = 'Geen resultaten gevonden voor deze start- en einddatum, kies een andere datum.';
          this.$data.isLoading = false;
          console.log(error);
        });
    },
    getRules() {
      axios
        .get(`${this.$store.state.APIurl}/rule`, {
          headers: { Authorization: `Bearer ${this.$store.state.user.token}` },
        })
        .then((response) => {
          this.$data.rules = response.data;
        })
        .catch((error) => {
          this.$data.rules = [];
        });
    },
    getFindingsAreaData() {
      axios
        .get(`${this.$store.state.APIurl}/finding/area-chart`, {
          headers: { Authorization: `Bearer ${this.$store.state.user.token}` },
        })
        .then((response) => {
          this.$data.findingsAreaData = response.data;
        })
        .catch((error) => {
          this.$data.findingsAreaData = [];
        });
    },
    getExpertStatistics() {
      axios
        .get(`${this.$store.state.APIurl}/statistics`, {
          headers: { Authorization: `Bearer ${this.$store.state.user.token}` },
        })
        .then((response) => {
          this.$data.expertStatistics = response.data;
        })
        .catch((error) => {
          this.$data.expertStatistics = undefined;
        });
    },
  },
  watch: {
    dates(newDates) {
      if (newDates.length === 2) {
        const startDate = new Date(newDates[0]);
        const endDate = new Date(newDates[1]);
        const oneYearLater = new Date(startDate);
        oneYearLater.setFullYear(startDate.getFullYear() + 1);
        console.log(startDate)

        if (endDate > oneYearLater) {
          this.$data.dates = [];
          this.$data.dateRangeText = '';
          this.$data.errorMessage = 'The selected dates must lie within one year or less.';
        } else {
          this.dateRangeText = `${newDates[0]} ~ ${newDates[1]}`;
        }
      }
    },
  },
  mounted() {
    this.getRules();
    this.getFindingsAreaData();
    this.getExpertStatistics();
  },
});
